import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1
  },
}));

export interface TabPanelProps {
  children?: React.ReactNode;
  currentIndex: number;
  tabIndex: number;
}

export const TabPanel = ({
  children,
  currentIndex,
  tabIndex,
  ...other
}: TabPanelProps) => {
  const classes = useStyles();
  return (
    currentIndex === tabIndex ? (
      <Box
        role="tabpanel"
        hidden={currentIndex !== tabIndex}
        id={`simple-tabpanel-${tabIndex}`}
        aria-labelledby={`simple-tab-${tabIndex}`}
        {...other}
        className={classes.root}
      >
        {children}
      </Box>
    ) : <></>
  );
};
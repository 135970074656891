import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Temperature from '../../assets/health-report/temperature.png';
import {
  ReportCard,
  ReportCardProps,
  ReportChart,
  ReportChartProps,
  ReportTable,
  ReportTableProps,
  StyledDivider,
  ChartDataProps,
} from '../../components';
import { average } from '../../utils/compute';
import { selectHeathReport, selectReportDate } from './store/selector';
import { IHealthReport } from './store/slice';
import { format, subDays, getTime } from 'date-fns';

type TemperatureReportProps = {
  reportDate?: number;
};

const TemperatureReport: React.FC<TemperatureReportProps> = ({
  reportDate = 0,
}) => {
  const data: IHealthReport[] = useSelector(selectHeathReport);

  if (data.length === 0 || data.filter((x) => x.temp !== 0).length === 0) {
    return (
      <Box className="no_record">
        データが <br />
        見つかりませんでした
      </Box>
    );
  }

  const averageTemp = average(
    data.map((x) => x.temp).filter((x) => x !== 0),
    1,
  );

  const reportCardProps: ReportCardProps = {
    icon: Temperature,
    averages: [`平均表面温度：${averageTemp}°C`],
  };

  const getRowColor = (temp: number) => {
    if (temp === 0) return '#E9E9E9';
    if (35 < temp && temp < 36) {
      return '#FFFDC6';
    }

    if (36 <= temp && temp <= 37) {
      return '#D9FFC7';
    }

    return '#FFB1B1';
  };

  const reportTableProps: ReportTableProps = {
    header: {
      temp: '平均表面温度',
    },
    reports: data.map((x) => ({
      datetime: x.datetime,
      temp: x.temp,
      rowColor: getRowColor(x.temp),
    })),
  };

  const bindData = () => {
    const result: ChartDataProps[] = [];

    for (let index = 0; index <= 6; index++) {
      const date = subDays(new Date(reportDate), index);

      const record = data.find(
        (d) => format(d.datetime, 'dd/MM/yyyy') === format(date, 'dd/MM/yyyy'),
      );
      const temp = record ? record.temp : 0;
      result.push({ datetime: getTime(date), temp });
    }

    return result;
  };

  const chartData: ReportChartProps = {
    data: [...bindData().reverse()],
  };

  return (
    <>
      <ReportChart {...chartData} />
      <StyledDivider />
      <ReportCard {...reportCardProps} />
      <StyledDivider />
      <ReportTable {...reportTableProps} />
    </>
  );
};

export default TemperatureReport;

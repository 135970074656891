import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    background: "#F9F9F9",
    borderRadius: 10,
  },
  content: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: 'center',
    padding: "15px 0 !important",
    "& .icon": {
      width: 50,
      height: 50
    },
    "& .averages": {
      display: "flex",
      flexDirection: "column",
      "& .avearage-text": {
        fontSize: 20,
        lineHeight: 1.5,
        color: '#FF8484',
        fontWeight: 700
      } 
    },
  },
}));

export interface ReportCardProps {
  icon: string;
  averages: string[];
}

export const ReportCard = ({ icon, averages }: ReportCardProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <img src={icon} alt="" className="icon" />
        <Box className="averages">
          {averages.map((x, index) => (
            <Typography key={index} className="avearage-text">{x}</Typography>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

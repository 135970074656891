import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Examination } from '../../../api/report-api.types';
import { actions, IHealthReport, User } from './slice';
import { reportApi } from '../../../api/report-api';

function* loadHealthReport(action: PayloadAction<User>) {
  try {
    const exams: Examination[] = yield call(
      reportApi.fetchHealthReport,
      action.payload,
    );

    const reports: IHealthReport[] = [];

    exams.forEach((exam) => {
      reports.push({
        bpm: exam.heartRate,
        datetime: exam.examTime,
        sbp: exam.sbp,
        dbp: exam.dbp,
        temp: exam.temperature,
        stress: '',
        weight: 0,
      });
    });

    yield put(
      actions.heathReportLoaded(
        reports.sort((a, b) => b.datetime - a.datetime),
      ),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.healthReportLoadedError());
  }
}

export default function* watchHealthReport() {
  yield takeLatest(actions.loadHealthReport.type, loadHealthReport);
}

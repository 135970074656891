import { combineReducers } from 'redux';
import { reducer as healthReportReducer } from '../app/containers/health-report/store/slice';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    healthReport: healthReportReducer,
    ...injectedReducers,
  });

  return rootReducer;
}

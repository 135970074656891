import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store, sagaMiddleware } from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
import rootSaga from "./store/rootSaga";
import { ThemeProvider } from "@material-ui/core";
import theme from "./app/theme";

sagaMiddleware.run(rootSaga);
const MOUNT_NODE = document.getElementById("root") as HTMLElement;

interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
    <Component />
    </ThemeProvider>
  </Provider>
);

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require("./App").App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/configureStore";

const selectDomain = (state: RootState) => {
  return state.healthReport;
}

export const selectHeathReport = createSelector(
  [selectDomain],
  (healthReport) => healthReport.data
);

export const selectUsername = createSelector(
  [selectDomain],
  (healthReport) => healthReport.user.username
)

export const selectReportDate = createSelector(
  [selectDomain],
  (healthReport) => Number(healthReport.user.date)
)

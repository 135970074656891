import { makeStyles } from '@material-ui/core';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  Legend,
  TooltipProps,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

import { formatDate } from '../../utils/datetime';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    left: -25,
    marginTop: 25,
    '& .recharts-surface': {
      overflow: 'visible',
    },
  },
});

const CustomizedLabel = (props: any) => {
  const { x, y, stroke, index, value, fractionDigits = 0, suffix } = props;

  return (
    <text x={x} y={y} dy={-8} fill={stroke} fontSize={10} textAnchor="middle">
      {index !== 0 &&
        value &&
        `${value.toFixed(fractionDigits)} ${suffix ?? ''}`}
    </text>
  );
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const date = payload.value === 0 ? '' : formatDate(payload.value, 'MMMdo');

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        fontSize={9}
      >
        {date}
      </text>
    </g>
  );
};

export interface ChartDataProps {
  datetime: number;
  bpm?: number;
  sbp?: number;
  dbp?: number;
  temp?: number;
  weight?: number;
}

export interface ReportChartProps {
  data: ChartDataProps[];
  ticks?: number[];
}
const indicatorSyle = {
  color: '#8884d8',
};
const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active) {
    let bpm = '';
    if (payload?.[0].payload.bpm != null) {
      bpm = 'bpm: ' + payload?.[0].payload.bpm;
    }

    let sbp = '';
    if (payload?.[0].payload.sbp != null) {
      sbp = 'sbp: ' + payload?.[0].payload.sbp;
    }

    let dbp = '';
    if (payload?.[0].payload.dbp != null) {
      dbp = 'dbp: ' + payload?.[0].payload.dbp;
    }

    let temp = '';
    if (payload?.[0].payload.temp != null) {
      temp = 'temperature: ' + payload?.[0].payload.temp;
    }

    let weight = '';
    if (payload?.[0].payload.weight != null) {
      temp = 'weight: ' + payload?.[0].payload.weight;
    }
    return (
      <div className="custom-tooltip">
        <p className="datetime">{`${formatDate(
          payload?.[0].payload.datetime,
          'MMMdo',
        )}`}</p>
        <p style={indicatorSyle}>{`${bpm}`}</p>
        <p style={indicatorSyle}>{`${sbp}`}</p>
        <p style={indicatorSyle}>{`${dbp}`}</p>
        <p style={indicatorSyle}>{`${temp}`}</p>
        <p style={indicatorSyle}>{`${weight}`}</p>
      </div>
    );
  }

  return null;
};

export const ReportChart = ({ data }: ReportChartProps) => {
  const classes = useStyles();
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        height={300}
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
        className={classes.root}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="datetime"
          height={60}
          tick={<CustomizedAxisTick />}
          interval={0}
        />
        <YAxis fontSize={12} interval={0} />
        <Tooltip content={<CustomTooltip />} />
        <Line type="monotone" dataKey="bpm" stroke="#8884d8">
          <LabelList content={<CustomizedLabel />} />
        </Line>
        <Line type="monotone" dataKey="sbp" stroke="#82ca9d">
          <LabelList content={<CustomizedLabel />} />
        </Line>
        <Line type="monotone" dataKey="dbp" stroke="#FF8484">
          <LabelList content={<CustomizedLabel />} />
        </Line>
        <Line type="monotone" dataKey="temp" stroke="#8884d8">
          <LabelList
            content={<CustomizedLabel fractionDigits={1} suffix="°C" />}
          />
        </Line>
        <Line type="monotone" dataKey="weight" stroke="#8884d8">
          <LabelList content={<CustomizedLabel fractionDigits={1} />} />
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

import { Box, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import BloodPressure from '../../assets/health-report/blood-pressure.png';
import {
  ChartDataProps,
  ReportCard,
  ReportCardProps,
  ReportChart,
  ReportChartProps,
  ReportTable,
  ReportTableProps,
  StyledDivider,
} from '../../components';
import { selectHeathReport, selectReportDate } from './store/selector';
import { IHealthReport, User } from './store/slice';
import { average } from '../../utils/compute';
import { format, getTime, subDays } from 'date-fns';
import { DEVICE_TYPE } from '.';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .pressure_level': {
      color: '#ABAFB3',
      fontSize: 12,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'right',
      lineHeight: 1,
    },
  },
});

type BloodPressureReportProps = {
  reportDate?: number;
  currentUser?: User;
};

const BloodPressureReport: React.FC<BloodPressureReportProps> = ({
  reportDate = 0,
  currentUser,
}) => {
  const classes = useStyles();
  const data: IHealthReport[] = useSelector(selectHeathReport);
  const isKEPDevice = currentUser?.deviceSerialNumber.startsWith(
    DEVICE_TYPE.KEP,
  );

  if (data.length === 0 || data.filter((x) => x.bpm !== 0).length === 0) {
    return (
      <Box className="no_record">
        データが <br />
        見つかりませんでした
      </Box>
    );
  }

  const averageBpm = average(data.map((x) => x.bpm).filter((x) => x !== 0));
  const averageSbp = average(data.map((x) => x.sbp).filter((x) => x !== 0));
  const averageDbp = average(data.map((x) => x.dbp).filter((x) => x !== 0));

  const reportCardProps: ReportCardProps = {
    icon: BloodPressure,
    averages: isKEPDevice
      ? [
          `平均最高血圧: ${averageSbp}`,
          `平均最低血圧: ${averageDbp}`,
          `平均脈拍： ${averageBpm}`,
        ]
      : [`平均脈拍： ${averageBpm}`],
  };

  const getRowColor = (stress: string) => {
    switch (stress) {
      case 'not stressed':
        return '#D9FFC7';
      case 'light stress':
        return '#FFFDC6';
      case 'moderate stress':
        return '#FED4AE';
      case 'severe stress':
        return '#FFB1B1';
      default:
        return '#E9E9E9';
    }
  };

  const reportTableProps: ReportTableProps = {
    header: {
      bpm: '脈拍',
      sbp: isKEPDevice ? '最高血圧' : '',
      dbp: isKEPDevice ? '最低血圧' : '',
    },
    reports: data.map((x) => ({
      datetime: x.datetime,
      bpm: x.bpm,
      sbp: x.sbp,
      dbp: x.dbp,
      rowColor: getRowColor(x.stress),
    })),
  };

  const bindData = () => {
    const result: ChartDataProps[] = [];

    for (let index = 0; index <= 6; index++) {
      const date = subDays(new Date(reportDate), index);

      const record = data.find(
        (d) => format(d.datetime, 'dd/MM/yyyy') === format(date, 'dd/MM/yyyy'),
      );

      let dataItem: ChartDataProps = {
        datetime: getTime(date),
        bpm: record?.bpm ?? 0,
      };

      if (isKEPDevice) {
        dataItem = {
          ...dataItem,
          sbp: record?.sbp ?? 0,
          dbp: record?.dbp ?? 0,
        };
      }

      result.push(dataItem);
    }

    return result;
  };

  const chartData: ReportChartProps = {
    data: [...bindData().reverse()],
  };

  return (
    <Box component="div" className={classes.root}>
      <ReportChart {...chartData} />
      <StyledDivider />
      <ReportCard {...reportCardProps} />
      <StyledDivider />
      <ReportTable {...reportTableProps} />
    </Box>
  );
};

export default BloodPressureReport;

import React from "react";
import "./App.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import GlobalStyles from "./app/globalStyles";

function App() {
  return (
    <BrowserRouter>
        <Routes />
      <GlobalStyles />
    </BrowserRouter>
  );
}

export default App;

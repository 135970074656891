import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface IHealthReport {
  datetime: number; //date-fns
  bpm: number;
  sbp: number;
  dbp: number;
  stress: string;
  temp: number;
  weight: number;
}

export interface QueryParams {
  date?: string;
  identifier?: string;
  serialNumber?: string;
}

export interface User {
  userId: string;
  username: string;
  date: number;
  identifier: string;
  deviceSerialNumber: string
}

export interface HealthReportState {
  data: IHealthReport[];
  user: User;
  loading: boolean;
  error: boolean;
}

export const initialState: HealthReportState = {
  data: [],
  user: {
    userId: '',
    username: '',
    date: 0,
    identifier: '',
    deviceSerialNumber: ''
  },
  loading: false,
  error: false,
};

const healthReportSlice = createSlice({
  name: "healthReport",
  initialState,
  reducers: {
    loadHealthReport(state: HealthReportState, action: PayloadAction<User>) {
      state.loading = true;
    },
    heathReportLoaded(
      state: HealthReportState,
      action: PayloadAction<IHealthReport[]>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload;
    },
    healthReportLoadedError(state: HealthReportState) {
      state.loading = false;
      state.error = true;
    },
    setUser(state: HealthReportState, action: PayloadAction<User>) {
      state.user = action.payload;
    }
  },
});

export const { actions, reducer, name: sliceKey } = healthReportSlice;

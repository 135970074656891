import { BaseApi } from './api';
import { AxiosResponse } from 'axios';
import { User } from '../containers/health-report/store/slice';
import { Examination } from './report-api.types';

const HEALTH_API = process.env.REACT_APP_HEALTH_API;
const INTERNAL_TOKEN = process.env.REACT_APP_INTERNAL_TOKEN;

class ReportApi extends BaseApi {
  fetchHealthReport = async (user: User): Promise<Examination[]> => {
    const axiosResponse: AxiosResponse = await this.axios.get(
      `${HEALTH_API}/api/healthReport/getReport?userId=${user.userId}`,
      {
        headers: {
          token: INTERNAL_TOKEN,
        },
      },
    );

    if (axiosResponse.status !== 200) {
      throw new Error(axiosResponse.statusText);
    }

    const response = axiosResponse.data;

    if (response.code !== 200) {
      throw new Error(axiosResponse.statusText);
    }

    return response.data;
  };
}

const reportApi = new ReportApi();
export { reportApi };

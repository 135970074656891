import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 2,
  },
});

const useNoteStyles = makeStyles({
  root: {
    fontSize: 12,
    color: "#ABAFB3",
    marginLeft: 5,
    marginRight: 5,
    display: "inline-flex",
    alignItems: "center",
    "&:before": {
      content: "''",
      width: 7,
      height: 7,
      background: (props: any) => props.color,
      borderRadius: 3.5,
      marginRight: 5,
    },
  },
});

export interface NoteProps {
  title: string;
  color: string;
}

export interface TableNoteProps {
  type: "bloodpressure" | "temperature";
}

const tempNotes: NoteProps[] = [
  {
    title: "低体温(>35)",
    color: "#FFFDC6",
  },
  {
    title: "正常(36~37)",
    color: "#D9FFC7",
  },
  {
    title: "微熱(<37)",
    color: "#FFB1B1",
  },
];

const bloodPressureNotes: NoteProps[] = [
  {
    title: "計測無し",
    color: "#E9E9E9",
  },
  {
    title: "なし",
    color: "#D9FFC7",
  },
  {
    title: "低",
    color: "#FFFDC6",
  },
  {
    title: "中",
    color: "#FED4AE",
  },
  {
    title: "高",
    color: "#FFB1B1",
  },
];

const Note = (props: NoteProps) => {
  const classes = useNoteStyles(props);

  return <li className={classes.root}>{props.title}</li>;
};

export const TableNote = ({ type }: TableNoteProps) => {
  const classes = useStyles();
  return (
    <ul className={classes.root}>
      {type === "bloodpressure"
        ? bloodPressureNotes.map((x, index) => <Note key={index} {...x} />)
        : tempNotes.map((x, index) => <Note key={index} {...x} />)}
      {}
    </ul>
  );
};
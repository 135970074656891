import { createTheme, colors } from '@material-ui/core';

const theme = createTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: colors.indigo[500],
    },
    secondary: {
      main: colors.indigo[500],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  typography: {
    fontFamily: "'M PLUS 1p', sans-serif",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255,0.8)',
      },
    },
  },
});

export default theme;

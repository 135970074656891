import { Routes, Route } from 'react-router-dom';
import { HealthReport } from './app/containers';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<HealthReport />} />
    </Routes>
  );
}

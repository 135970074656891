import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%',
        fontFamily: "'M PLUS 1p', sans-serif",
      },
      a: {
        textDecoration: 'none',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.report_title': {
        fontSize: 26,
        width: '100%',
        textAlign: 'left',
        lineHeight: 1.5,
        color: '#464A53',
      },
      '.report_datetime': {
        width: '100%',
        textAlign: 'left',
        lineHeight: 1,
        fontSize: 14,
        marginBottom: 20,
      },
      '.no_record': {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 30,
      },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;

import {
  AppBar,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { User } from '../../containers/health-report/store/slice';
import { DEVICE_TYPE } from '../../containers/health-report';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    height: 50,
    borderRadius: 20,
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 50,
    padding: '5px 0',
  },
})((props: any) => <Tabs {...props} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#717171',
    'font-weight': theme.typography.fontWeightRegular,
    fontSize: 15,
    minHeight: 'unset',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#FF8484',
      borderRadius: 20,
    },
  },
}))((props: any) => <Tab disableRipple {...props} />);

export interface NavbarProps {
  currentIndex: number;
  setCurrentIndex: (id: number) => void;
  currentUser?: User;
}

export const Navbar: React.FC<NavbarProps> = ({
  currentIndex = 1,
  setCurrentIndex,
  currentUser,
}) => {
  const classes = useStyles();

  const handleChange = (event: any, newValue: number) => {
    setCurrentIndex(newValue);
  };

  return (
    <AppBar position="static" className={classes.root}>
      <StyledTabs
        value={currentIndex}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {currentUser?.deviceSerialNumber.startsWith(
          DEVICE_TYPE.B0002,
        ) ? null : (
          <StyledTab label="表面温度" />
        )}
        <StyledTab label="脈拍" />
      </StyledTabs>
    </AppBar>
  );
};

import { ja } from 'date-fns/locale';
import { format } from 'date-fns';
import NanoDate from 'nano-date';

export type PATTERN = 'yoMMMdo' | 'MMMdo' | 'hmma';

export function formatDate(datetime: number, pattern: PATTERN) {
  return format(datetime, pattern, { locale: ja });
}

export function formatTime(datetime: number) {
  return format(datetime, 'h:mma');
}

export function nanosToMilis(nanos: number) {
  return new Date(new NanoDate(`${nanos}`).toISOString()).valueOf();
}

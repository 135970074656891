import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { formatDate, formatTime } from "../../utils/datetime";

const useStyles = makeStyles({
  root: {
    border: "0.487646px solid #C3C5CB",
  },
});

const StyledCell = withStyles((theme: Theme) => ({
  head: {
    fontWeight: 600,
    color: "#464A53",
  },
  body: {
    color: "#000000",
    fontSize: 16,
  },
  sizeSmall: {
    padding: "6px 8px 6px 4px",
    "&:last-child": {
      paddingRight: 4,
    },
  },
}))(TableCell);

const StyledDateTimeCell = withStyles((theme: Theme) => ({
  body: {
    fontSize: 11,
  },
}))(StyledCell);

const StyledRow = withStyles((theme: Theme) => ({
  root: {
    background: (props: any) =>
      props.rowcolor ? props.rowcolor : theme.palette.background.paper,
    margin: "2px 0",
  },
}))(TableRow);

export interface HeaderProps {
  bpm?: string;
  sbp?: string;
  dbp?: string;
  temp?: string;
  weight?: string;
}

export interface ReportProps {
  datetime: number;
  bpm?: number;
  sbp?: number;
  dbp?: number;
  temp?: number;
  weight?: number;
  rowColor?: string;
}

export interface ReportTableProps {
  header: HeaderProps;
  reports: ReportProps[];
}

export const ReportTable = ({ header, reports }: ReportTableProps) => {
  const { bpm, sbp, dbp, temp, weight } = header;
  const classes = useStyles();
  return (
    <TableContainer className={classes.root}>
      <Table aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            <StyledCell align="left">日付</StyledCell>
            {sbp && <StyledCell align="left">{sbp}</StyledCell>}
            {dbp && <StyledCell align="left">{dbp}</StyledCell>}
            {bpm && <StyledCell align="left">{bpm}</StyledCell>}
            {temp && <StyledCell align="left">{temp}</StyledCell>}
            {weight && <StyledCell align="left">{weight}</StyledCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.length > 0 && reports.map((x: ReportProps, index) => (
            <StyledRow key={index} rowcolor={x.rowColor}>
              <StyledDateTimeCell align="left">
                {formatDate(x.datetime, "MMMdo")}
                <br />
                {formatTime(x.datetime)}
              </StyledDateTimeCell>
              {sbp && <StyledCell align="left">{x.sbp}</StyledCell>}
              {dbp && <StyledCell align="left">{x.dbp}</StyledCell>}
              {bpm && <StyledCell align="left">{x.bpm}</StyledCell>}
              {temp && (
                <StyledCell align="left">
                  {x.temp === 0 ? x.temp : x.temp?.toFixed(1)}°C
                </StyledCell>
              )}
              {weight && (
                <StyledCell align="left">
                  {x.weight === 0 ? x.weight : x.weight?.toFixed(1)} kg
                </StyledCell>
              )}
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
